<template>
  <div>
    <!-- 学员信息 -->
    <stu-info @select="handleStudentSelected" :isCreatStudents="false" :queryList="stuQueryList"/>
    <div class="margin-top padding bg-white">
      <border-title titleName="补费信息" />
      <div v-show="!isShow">
        <div class="margin-top-sm">
          <el-table border :data="ArrearInfo" style="width: 100%">
            <el-table-column prop="order_id" label="订单号"></el-table-column>
            <el-table-column prop="type_name" label="订单类型"></el-table-column>
            <el-table-column prop="order.add_user_name" label="经办人"></el-table-column>
            <el-table-column prop="add_time" label="经办日期">
              <template slot-scope="scope">
                <span class="">{{ scope.row.add_time.slice(0, 10) }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="order.handle_school_name" label="经办校区"></el-table-column>
            <el-table-column prop="overdueItems" label="欠费项目" width="200"></el-table-column>
            <el-table-column prop="arrears_money" label="欠费金额"></el-table-column>
            <el-table-column label="本次补费金额" prop="money">
              <template slot-scope="scope">
                <el-input-number
                  @change="moneyChange"
                  v-model="scope.row.money"
                  controls-position="right"
                  :min="0"
                  :max="scope.row.arrears_money"
                  style="width: 120px;"
                  size="mini"
                  :precision="2"
                />
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="margin-top flex justify-content-flexEnd">
          <span class="text-grey">
            补费金额：
            <span class="text-bold text-black">{{ shouldMoney }}</span>
            元
          </span>
        </div>
        <div class="tip padding-top flex aligin-items-center justify-content-between">
          <div class="flex aligin-items-center tip-item flex1">
            <div class="text-grey">对内备注</div>
            <div class="margin-left tip-item-input"><el-input v-model="backExpenseInfo.order_in_remark" placeholder="该备注只有内部操作人员可见" size="mini" /></div>
          </div>
          <div class="flex aligin-items-center tip-item flex1">
            <div class="text-grey">对外备注</div>
            <div class="margin-left tip-item-input"><el-input v-model="backExpenseInfo.order_outer_remak" placeholder="该备注将会被打印且会在校宝家中显示" size="mini" /></div>
          </div>
        </div>
      </div>

      <div class="no-curriculums" v-show="isShow">
        <i class="el-icon-circle-plus-outline"></i>
        <p>请点击左上方按钮选择报名信息</p>
      </div>
    </div>

    <!-- 支付信息 -->
    <pay-info
      v-show="!isShow"
      :totalPrice="shouldMoney"
      :AllBalance="overRecharge"
      @change="payChange"
      @schoolChange="schoolChange"
      @usedBalanceChange="usedBalanceChange"
    />
    <!-- 订单标签 -->
    <order-tag @change="orderChange" />
    <!-- 经办信息 -->
    <handle-info :isSales="false" @change="handelChange" />

    <div class="sign-up-bottom padding">
      <div class="sign-up-bottom-btn"><el-button v-preventReClick="1000" @click="submite" type="primary" size="medium">保存</el-button></div>
    </div>
  </div>
</template>

<script>
import StuInfo from '../components/stuInfo.vue';
import HandleInfo from '../components/handleInfo.vue';
import OrderTag from '../components/orderTag.vue';
import PayInfo from '../components/payInfo.vue';
export default {
  components: {
    StuInfo,
    OrderTag,
    HandleInfo,
    PayInfo
  },
  data() {
    return {
      isShow: true,
      ArrearInfo: [],
      tipInput: '',
      overRecharge: 0,
      shouldMoney: 0,
      stuQueryList: {
        is_arrears: true
      },
      backExpenseInfo: {
        collections: [
          {
            pay_type: 0, // 付款类型
            // opposite_account_number: '', // 对方账号
            // serial_number: '', // 流水编号 ,
            money: 0, // 收支金额
            // remark: '',
            // source: 0 //付款记录来源 对应枚举 PayTypeSorce
          }
        ],
        student_id: 0,
        total_money: 0,
        school_id: 0, // 订单添加所属校区id
        UsedBalance: 0, // 使用余额
        IncreaseBalance : 0, // 增加余额
        order_price: 0,
        order_id: 0,
        order_in_remark: '', // 对内备注
        order_outer_remak: '', // 对外备注
        order_tags: '', // 订单标签(逗号隔开)
        sale_user: 0, // 销售员id ,
        handle_school: 0, //  经办校区id ,
        handle_date: '', // 经办日期
        details: [
        ]
      }
    };
  },
  created() {},
  mounted() {},
  watch: {
    ArrearInfo: {
      deep: true,
      handler(val) {
        console.log(val);
        this.shouldMoney = 0
        val.map(t => {
          this.shouldMoney += t.money;
          return t;
        })
      }
    }
  },
  methods: {
    //选择了学员
    handleStudentSelected(student) {
      if (student) {
        this.isShow = false;
        this.backExpenseInfo.student_id = student.id;
        this.API.GetSupplementaryCurriculumOrderList(student.id).then(res => {
          if(res) {
            res.map(t => {
              t.money = t.arrears_money;
              this.shouldMoney += t.money;
              // this.backExpenseInfo.total_money += t.arrears_money;
              return t;
            });
            this.backExpenseInfo.order_id = res[0].order.id
            this.ArrearInfo = res;
          }else {
            
          }
          
        });
        this.API.GetBalance(student.id).then(res => {
          this.overRecharge = res.data.toFixed(2)*1
        })
      }
    },
    // 补费金额变化
    moneyChange() {
      this.backExpenseInfo.total_money = 0;
      // this.ArrearInfo.map(t => {
      //   this.backExpenseInfo.total_money += t.money;
      //   return t;
      // });
    },
    // 使用余额变化
    usedBalanceChange(e) {
      // this.backExpenseInfo.total_money = e.totalPice
    },
    // 获取需补费订单信息
    GetSupplementaryCurriculumOrderList() {},
    // 经办校区选择
    schoolChange(e) {
      this.backExpenseInfo.school_id = e;
      this.backExpenseInfo.handle_school = e;
    },
    // 支付信息
    payChange(e) {
      this.backExpenseInfo.collections = e.payList;
      this.backExpenseInfo.IncreaseBalance = e.IncreaseBalance;
      this.backExpenseInfo.UsedBalance = e.UsedBalance;
      this.backExpenseInfo.handle_school = e.handle_school;
      this.backExpenseInfo.school_id = e.handle_school;
      // this.backExpenseInfo.total_money = e.totalPrice
      console.log(this.backExpenseInfo.total_money);
    },
    // 订单标签选择
    orderChange(e) {
      this.backExpenseInfo.order_tags = e.join(',');
    },
    // 日期选择
    handelChange(e) {
      this.backExpenseInfo.handle_date = e.handle_date;
      this.backExpenseInfo.handle_user = e.handle_user;
      this.backExpenseInfo.sale_user = e.sale_user;
    },
    submite() {
      let obj = this.backExpenseInfo;
      this.ArrearInfo.map(t => {
        let arr = {
          order_curriculum_id: t.id,
          money: t.money,
          arrears_money: t.arrears_money
        }
        obj.details.push(arr)
        return t;
      });
      // obj.order_price = obj.total_money  +  this.backExpenseInfo.UsedBalance
      // obj.total_money = obj.order_price
      obj.collections.map(t => {
        obj.total_money = 0
        obj.total_money += t.money
      })
      obj.total_money += obj.UsedBalance
      obj.order_price = obj.total_money
      this.backExpenseInfo.details.map(t => {
        t.arrears_money = t.money < t.arrears_money ? t.arrears_money - t.money : 0;
        return t;
      });
      console.log(JSON.stringify(obj));
      this.API.SupplementaryFee(obj).then(res => {
        if (res.success) {
          this.$message.success(res.message);
          this.$router.go(-1)
        } else {
          this.$message.error(res.message);
        }
      });
    }
  }
};
</script>

<style scoped>
.tip {
  width: 100%;
}
.tip-item {
  width: 100%;
}
.tip-item-input {
  width: 85%;
}
.sign-up-bottom {
  bottom: 0;
  right: 0;
  position: fixed;
}
.no-curriculums {
  min-height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #ccc;
}
</style>
